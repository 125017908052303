<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.7961 20.2041L17.3439 15.75C18.6788 14.0104 19.302 11.8282 19.0871 9.64606C18.8723 7.46389 17.8354 5.44515 16.1868 3.99936C14.5383 2.55356 12.4015 1.78898 10.21 1.8607C8.01841 1.93243 5.9362 2.83509 4.3857 4.38558C2.83521 5.93607 1.93255 8.01829 1.86083 10.2098C1.7891 12.4014 2.55369 14.5382 3.99948 16.1867C5.44527 17.8353 7.46401 18.8722 9.64619 19.087C11.8284 19.3019 14.0106 18.6787 15.7501 17.3437L20.2061 21.8006C20.3107 21.9053 20.4349 21.9883 20.5717 22.0449C20.7084 22.1015 20.8549 22.1307 21.0029 22.1307C21.1509 22.1307 21.2975 22.1015 21.4342 22.0449C21.5709 21.9883 21.6952 21.9053 21.7998 21.8006C21.9044 21.696 21.9875 21.5717 22.0441 21.435C22.1007 21.2983 22.1299 21.1517 22.1299 21.0037C22.1299 20.8557 22.1007 20.7092 22.0441 20.5725C21.9875 20.4357 21.9044 20.3115 21.7998 20.2069L21.7961 20.2041ZM4.12512 10.5C4.12512 9.23914 4.499 8.0066 5.1995 6.95823C5.89999 5.90987 6.89563 5.09277 8.06051 4.61026C9.22539 4.12775 10.5072 4.00151 11.7438 4.24749C12.9804 4.49347 14.1164 5.10063 15.0079 5.99219C15.8995 6.88375 16.5066 8.01966 16.7526 9.25629C16.9986 10.4929 16.8724 11.7747 16.3898 12.9396C15.9073 14.1045 15.0902 15.1001 14.0419 15.8006C12.9935 16.5011 11.761 16.875 10.5001 16.875C8.80989 16.8733 7.1894 16.201 5.99423 15.0059C4.79906 13.8107 4.12685 12.1902 4.12512 10.5Z"
      fill="#333333"
    />
  </svg>
</template>

<script lang="ts" setup></script>
